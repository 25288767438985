namespace UHTMLAnchorElement {
  export namespace Target {
    export function isSelf(target: string | undefined) {
      if (!target || target === "_self") return true;
      if (target === "_blank") return false;

      // Other targets reference self if page doesn't have parent
      return window.parent === window;
    }
  }
}

export default UHTMLAnchorElement;
